class OrganisationDetails extends React.Component {
  constructor(props) {
    super(props)
    this.props = props

    this.state = {
      shouldValidate: false,
      accountName: "",
      address: "",
      address2: "",
      zip: "",
      city: "",
      country: "",
      phone: "",
      twoFactorAccepted: false,
      twoFactorNumber: "",
      twoFactorVerified: false,
      checked: false,
      contactPerson: "",
      contactEmail: "",
      cvrEan: "",
      employeeId: "",
      errorMsg: "",
      showChangePlan: false,
      switchToVerification: false,
      twoFactorCode: "",
      isTwoFactorVerified: false,
      verificationId: "",
    }

    this.areCardsValidated = this.areCardsValidated.bind(this)
    this.updateFormState = this.updateFormState.bind(this)
    this.accountUpdated = this.accountUpdated.bind(this)
    this.accountUpdateFailed = this.accountUpdateFailed.bind(this)
    this.updateAccount = this.updateAccount.bind(this)
    this.keyDownHandler = this.keyDownHandler.bind(this)
    this.showChangePlan = this.showChangePlan.bind(this)
    this.showCurrentPlan = this.showCurrentPlan.bind(this)
    this.onConfirmPlan = this.onConfirmPlan.bind(this)

    this.updateOrSwitchCard = this.updateOrSwitchCard.bind(this)

    this.onCodeSubmit = this.onCodeSubmit.bind(this)
    this.fieldUpdated = this.fieldUpdated.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    componentHandler.upgradeDom()
  }

  componentDidMount() {
    if (this.props.account) {
      firebase.database().ref(DkScanHowDbRefs.Accounts + "/" + this.props.account.accountKey + "/" + DkScanHowDbRefs.Public).once("value").then((snapshot) => {
        if (snapshot && snapshot.val()) {
          this.setState({
            checked: snapshot.val().twoFactorAccepted,
            accountName: snapshot.val().accountName,
            address: snapshot.val().address,
            address2: snapshot.val().address2,
            zip: snapshot.val().zip,
            city: snapshot.val().city,
            country: snapshot.val().country,
            phone: snapshot.val().phone,
            twoFactorAccepted: snapshot.val().twoFactorAccepted ? snapshot.val().twoFactorAccepted : false,
            twoFactorNumber: snapshot.val().twoFactorNumber ? snapshot.val().twoFactorNumber : "",
            isTwoFactorVerified: snapshot.val().twoFactorVerified ? snapshot.val().twoFactorVerified : false,
            contactPerson: snapshot.val().contactPerson,
            contactEmail: snapshot.val().contactEmail,
            cvrEan: snapshot.val().cvrEan,
            employeeId: snapshot.val().employeeId,
          })
        }
      }, function(error) {
        // An error happened.
        DkScanHowHelpers.logError(error)
      })
    }
    componentHandler.upgradeDom()
  }

  validateForm() {
    if (this.state.email != "" && this.state.pass1 != "" && this.state.pass2 != "" && this.state.pass1 == this.state.pass2 && this.state.accountName != "") {

    }
  }

  updateFormState(key, value) {
    this.setState({ [key]: value }, () => this.validateForm())
  }

  onCodeSubmit(e) {
    if (e.keyCode === 13) {
      this.updateOrSwitchCard()
    }
  }

  configureCaptcha() {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("sign-in-button", {
      "size": "invisible",
      "callback": (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        this.onSignInSubmit()
      },
      defaultCountry: "DK",
    })
  }

  onSignInSubmit(e) {
    e.preventDefault()
    this.configureCaptcha()
    const phoneNumber = "+" + this.state.twoFactorNumber
    const appVerifier = window.recaptchaVerifier
    firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult
        console.log("OTP has been sent")
        // ...
      }).catch((error) => {
      // Error; SMS not sent
      // ...
      console.log("SMS not sent")
      console.log("Error -->", error)
    })
  }

  async sendCode() {
    this.configureCaptcha()
    let number = "+" + this.state.twoFactorNumber
    const appVerifier = window.recaptchaVerifier
    let provider = new firebase.auth.PhoneAuthProvider()
    await provider.verifyPhoneNumber(number, appVerifier).then((verifiationData) => {
      this.setState({
        verificationId: verifiationData,
      })
    }).catch((error) => {
      this.setState({ errorMsg: DkScanHowLocalisation.t("two_factor_wrong_code_or_phone_used") })
    })
  }

  async updateOrSwitchCard(e) {
    e.preventDefault()
    this.updateAccount()
  }


  updateAccount() {
    firebase.database().ref(DkScanHowDbRefs.Accounts + "/" + this.props.account.accountKey + "/" + DkScanHowDbRefs.Public).update({
      accountName: this.state.accountName,
      address: this.state.address,
      address2: this.state.address2 == undefined ? "" : this.state.address2,
      zip: this.state.zip,
      city: this.state.city,
      country: this.state.country,
      phone: this.state.phone,
      twoFactorAccepted: this.state.twoFactorAccepted,
      twoFactorNumber: this.state.twoFactorAccepted ? this.state.twoFactorNumber : "",
      twoFactorVerified: !this.state.twoFactorAccepted ? false : this.state.twoFactorVerified,
      contactPerson: this.state.contactPerson,
      contactEmail: this.state.contactEmail,
      cvrEan: this.state.cvrEan,
      employeeId: this.state.employeeId == undefined ? "" : this.state.employeeId,
      modifiedTime: firebase.database.ServerValue.TIMESTAMP,
    }).then(
      this.accountUpdated,
      this.accountUpdateFailed,
    )
  }

  accountUpdated() {
    this.setState({ errorMsg: "" })
    window.location.reload()
    showSnackbar("Oplysningerne er opdateret")
  }

  accountUpdateFailed(error) {
    this.setState({ errorMsg: DkScanHowLocalisation.t("web_general_errormsg_anerroroccurred") })
    console.log("accountUpdateFailed..:" + error)
    DkScanHowHelpers.logError(error)
  }

  areCardsValidated() {
    return this.state.adminName != "" &&
      this.state.accountName != "" &&
      this.state.address != "" &&
      this.state.zip != "" &&
      this.state.city != "" &&
      this.state.country != "" &&
      this.state.phone != "" &&
      this.state.contactPerson != "" &&
      this.state.contactEmail != "" &&
      this.state.cvrEan &&
      (this.state.twoFactorAccepted && this.state.twoFactorNumber.length > 1 || !this.state.twoFactorAccepted && this.state.twoFactorNumber.length < 1)
  }

  keyDownHandler(e) {
    //Pressed enter
    if (e.keyCode === 13 && this.areCardsValidated()) {
      this.updateOrSwitchCard()
    }
  }

  showChangePlan() {
    this.setState({ showChangePlan: true })
  }

  showCurrentPlan() {
    this.setState({ showChangePlan: false })
  }

  onConfirmPlan() {
    this.showCurrentPlan()
  }

  fieldUpdated(e) {
    const name = e.target.name
    const value = e.target.value
    this.updateFormState(name, value)
  }

  phoneVerificationCard() {
    return <div>
      <ScanHowInputTextField fieldName="twoFactorCode"
                             placeholderText={DkScanHowLocalisation.t("two_factor_phone")}
                             value={this.state.twoFactorCode}
                             onChange={this.fieldUpdated}
                             keyDownHandler={this.onCodeSubmit} />
    </div>
  }

  organisationInfoCard() {
    console.log('Here -->', this.state.twoFactorNumber)
    return <OrganisationDataCard updateFormState={this.updateFormState}
                                 accountName={this.state.accountName} address={this.state.address}
                                 address2={this.state.address2} zip={this.state.zip}
                                 city={this.state.city} country={this.state.country}
                                 phone={this.state.phone} contactPerson={this.state.contactPerson}
                                 twoFactorAccepted={this.state.twoFactorAccepted}
                                 twoFactorNumber={this.state.twoFactorNumber ? this.state.twoFactorNumber.replace("+", "") : ""}
                                 contactEmail={this.state.contactEmail} cvrEan={this.state.cvrEan}
                                 employeeId={this.state.employeeId}
                                 keyDownHandler={this.keyDownHandler}
                                 showTwoFactor={true} />
  }

  render() {
    return (
      <div>
        <br /><br />
        {this.state.showChangePlan ?
          <ConfirmPlan chosenPlan={this.props.account.confirmedPlan} onConfirmPlan={this.onConfirmPlan}
                       authUser={this.props.authUser} account={this.props.account} onCancel={this.showCurrentPlan} />
          : null}
        <div className={"mdl-grid mdl-grid--no-spacing " + (this.state.showChangePlan ? "displayNone" : "")}>
          <div className="mdl-cell--1-col" />
          <div className="mdl-cell--3-col">
            <div id={"sign-in-button"} />
            {/*<form onSubmit={this.updateOrSwitchCard}>*/}
            <b>{this.state.switchToVerification ? DkScanHowLocalisation.t("two_factor_enter_code") : DkScanHowLocalisation.t("web_overview_label_hereyoucanchangeyourdata")}</b><br /><br />
            <b>{!this.state.isTwoFactorVerified ? DkScanHowLocalisation.t("two_factor_enable_optional") : null}</b><br /><br />
            {!this.state.switchToVerification ? this.organisationInfoCard() : null}
            <br />
            <div className="right">
              <div className="accountFormErrorMessage">{this.state.errorMsg}</div>
              <ScanHowButton
                id={"mfa-button"}
                text={!this.state.switchToVerification && this.state.twoFactorAccepted && this.state.twoFactorNumber.length > 1 ? DkScanHowLocalisation.t("continue") : DkScanHowLocalisation.t("web_overview_btn_update")}
                onClick={this.updateOrSwitchCard}
                isDisabled={!this.areCardsValidated()} />
            </div>
            {/*</form>*/}
          </div>
          <div className="mdl-layout-spacer"></div>
          <div className="mdl-cell--3-col">
            <CurrentPackageCard account={this.props.account} authUser={this.props.authUser}
                                showChangePlan={this.showChangePlan} />

          </div>
          <div className="mdl-cell--1-col" />
        </div>
      </div>
    )
  }
}

OrganisationDetails.propTypes = {
  account: React.PropTypes.object.isRequired,
}

class CurrentPackageCard extends React.Component {
  constructor(props) {
    super(props)
    // This binding is necessary to make `this` work in the callback
    this.props = props
    this.state = { accountType: null, noOfUsers: {} }
    this.usersLine = this.usersLine
  }

  componentDidUpdate(prevProps, prevState) {
    componentHandler.upgradeDom()
  }

  componentDidMount() {
    firebase.database().ref(DkScanHowDbRefs.Stats_Accounts + "/" + this.props.account.accountKey + "/" + DkScanHowDbRefs.NoOfUsers).on("value", (snapshot) => {
      if (snapshot && snapshot.val()) {
        this.setState({ noOfUsers: snapshot.val() })
      }
    }, function(error) {
      // An error happened.
      DkScanHowHelpers.logError(error)
    })


    firebase.database().ref("accountTypes/").orderByChild("id").equalTo(this.props.account.accountType).limitToFirst(1).once("value").then((snapshot) => {
      if (snapshot) {
        let accountType = DkScanHowHelpers.objectToArray(snapshot.val())[0]
        if (!this.state.accountType) {
          this.setState({
            accountType: accountType,
          })
        }
      }
    }, function(error) {
      // An error happened.
      DkScanHowHelpers.logError(error)
    })

    componentHandler.upgradeDom()
  }

  usersLine(title, limitForRole, role) {
    if (limitForRole != null) {
      var used = DkScanHowHelpers.getJsonDescendant(this.state.noOfUsers, role, 0, 0)
      return <tr>
        <td>{title + " : "}</td>
        <td>{used + " / " + limitForRole}</td>
      </tr>
    } else {
      return null
    }
  }

  render() {
    var packageLine = null
    var extraStorageLine = null
    var extraWorkflowsLine = null
    var extraCourseLine = null
    var extraSupportLine = null
    var purchasesTitleLine = null

    var adminsLine = null
    var creatorsLine = null
    var viewersLine = null
    var workflowsLine = null
    var storageLine = null

    if (this.props.account.latestPurchase) {
      var latestPurchase = this.props.account.latestPurchase

      if (latestPurchase.package) {
        packageLine = <tr>
          <td>{DkScanHowLocalisation.t("web_createorganisation_label_package")} :</td>
          <td>{latestPurchase.package.title}</td>
        </tr>
      }

      /* //275 remove course/support options
      if( latestPurchase.support ){
          extraSupportLine = <tr><td>{DkScanHowLocalisation.t("web_overview_accountcard_label_support")} : </td><td>{latestPurchase.support.title}</td></tr>;
      }
      */

      if (latestPurchase.workflows) {
        extraWorkflowsLine = <tr>
          <td>{DkScanHowLocalisation.t("web_overview_accountcard_label_workflows")} :</td>
          <td>{latestPurchase.workflows.title}</td>
        </tr>
      }

      if (latestPurchase.storage) {
        extraStorageLine = <tr>
          <td>{DkScanHowLocalisation.t("web_overview_accountcard_label_storagespace")} :</td>
          <td>{latestPurchase.storage.title}</td>
        </tr>
      }

      /* //275 remove course/support options
      if( latestPurchase.courses ){
          extraCourseLine = <tr><td>{DkScanHowLocalisation.t("web_overview_accountcard_label_course")} : </td><td>{latestPurchase.courses.title}</td></tr>;
      }
      */

      if (extraCourseLine || extraSupportLine || extraWorkflowsLine || extraStorageLine) {
        purchasesTitleLine = <tr>
          <td colSpan="2"><b>{DkScanHowLocalisation.t("web_overview_accountcard_subtitle_extras")}</b></td>
        </tr>
      }

      adminsLine = this.usersLine(DkScanHowLocalisation.t("web_general_terms_admins"), this.props.account.allowedAdmins, "admin")

      creatorsLine = this.usersLine(DkScanHowLocalisation.t("web_general_terms_creators"), this.props.account.allowedCreators, "creator")

      viewersLine = this.usersLine(DkScanHowLocalisation.t("web_general_terms_viewers"), this.props.account.allowedViewers, "viewer")

      if (this.props.account.allowedStorageInGB != null) {
        storageLine = <tr>
          <td>{DkScanHowLocalisation.t("web_overview_accountcard_label_storage")} :</td>
          <td>{this.props.account.allowedStorageInGB} GB</td>
        </tr>
      }
      if (this.props.account.allowedNoOfWorkflows != null) {
        workflowsLine = <tr>
          <td>{DkScanHowLocalisation.t("web_overview_accountcard_label_workflows")} :</td>
          <td>{this.props.account.allowedNoOfWorkflows}</td>
        </tr>
      }
    }

    return (
      <div>
        <div className="mdl-card mdl-shadow--2dp fullWidth">
          <div className="mdl-card__title">
            <h2
              className="mdl-card__title-text">{DkScanHowLocalisation.t("web_overview_accountcard_title_youraccount")}</h2>
          </div>
          <div className="mdl-card__supporting-text ">
            <table>
              <tbody>
              {packageLine}
              <tr>
                <td colSpan="2">&nbsp;</td>
              </tr>
              {purchasesTitleLine}
              {extraCourseLine}
              {extraSupportLine}
              {extraWorkflowsLine}
              {extraStorageLine}

              <tr>
                <td colSpan="2">&nbsp;</td>
              </tr>
              {adminsLine}
              {creatorsLine}
              {viewersLine}
              {workflowsLine}
              {storageLine}
              <tr>
                <td colSpan="2">
                  <br />
                  <a className="cursorPointer"
                     onClick={this.props.showChangePlan}>{DkScanHowLocalisation.t("web_overview_accountcard_link_extendyourplan")}</a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

CurrentPackageCard.defaultProps = {}

CurrentPackageCard.propTypes = {
  account: React.PropTypes.object.isRequired,
}
